import React from 'react';
import './solicita.css';
import {Link} from 'react-router-dom';

function Solicita(){
    return <div className="d-flex align-items-center text-center form-container">
      <form className="form-signin">
        <img className="mb-4" src="/Images/cca/logo cca.PNG" alt="" width="72" height="57" />
        <h1 className="h3 mb-3 fw-normal">Solicitar acesso</h1>

        <div className="form-floating">
          <input type="email" className="form-control" id="floatingInput" placeholder="E-mail" />
          <label for="floatingInput">E-mail</label>
        </div>

        <button className="w-100 btn btn-lg btn-primary" type="submit">Enviar Solicitação</button>

        <div className="login-links mt-5">
          <Link to="/app" className="mx-3">Já tenho acesso</Link>
        </div>

        <p className="mt-5 mb-3 text-muted">&copy; Desenvolvido por RW Sistemas</p>
    </form>
  </div>;
  }

export default Solicita;