import React from 'react';
import Menu from './menu';
import Colabore from './Colabore/colabore';

function Colaborehome(){
    return <div>
      <Menu/>
      <Colabore/>
    </div>;
  }

export default Colaborehome;