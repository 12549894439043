import React from 'react';

function Banner(){
    return <section id="banner">
        <div className="container">
            <div className="row">

                <div className="col-lg-6">
                    <h1>Baixe o nosso APP</h1> 
                    <h4>E tenha toda informação em suas mãos</h4> 

                    <a href="https://play.google.com/store/apps/details?id=cca.app.cca" className="btn btn-outline-light btn-lg btn-banner">Baixar Android</a>

                    <a href="https://apps.apple.com/br/app/cca-apucarana/id1540188343"  className="btn btn-outline-light btn-lg btn-banner">Baixar IOS</a>
                </div>

                <div className="col-lg-6">
                  <img src="images/cca/banner_celular.png" />
                </div>                

            </div>
        </div>
    </section>;
  }

export default Banner;