import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

/* Paginas */
import Site from './site/site.jsx';
import Login from './app/Login/login.jsx';
import Solicita from './app/SolicitaUsua/solicita.jsx';
import ResetSenha from './app/ResetSenha/resetsenha.jsx';
import Home from './app/Home/home.jsx';
import Encontro from './app/Encontro/encontro.jsx';
import Contatohome from './site/Components/contatohome.jsx';
import Contato from './site/Components/Contato/contato.jsx';
import Colaborehome from './site/Components/colaborehome.jsx';

function App(){
    return <BrowserRouter>
    <Route exact path='/' component={Site} />    
    <Route exact path='/app' component={Login} />    
    <Route exact path='/app/solicita' component={Solicita} />   
    <Route exact path='/app/resetsenha' component={ResetSenha} /> 
    <Route exact path='/app/home' component={Home} /> 
    <Route exact path='/app/encontro' component={Encontro} /> 
    <Route exact path='/contato' component={Contatohome} /> 
    <Route exact path='/colabore' component={Colaborehome} /> 
    </BrowserRouter>;
  }

export default App;