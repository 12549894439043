import React from 'react';
import './home.css';
import Navbar from '../Components/Navbar/navbar.jsx';

function Home(){
    return <div>
      <Navbar/>
  </div>;
  }

export default Home;