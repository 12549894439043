import React from 'react';

function Footer(){
    var ano = new Date().getFullYear();

    return 	<section id="footer">
            <div>
                <ul className="list-unstyled list-inline social text-center">
                    <li className="list-inline-item"><a href="https://www.facebook.com/comunidadecristadeapucarana"><i className="fa fa-facebook"></i></a></li>
                    <li className="list-inline-item"><a href="https://www.youtube.com/ccatvon"><i className="fab fa-youtube"></i></a></li>
                    <li className="list-inline-item"><a href="https://www.instagram.com/cca_apucarana/"><i className="fa fa-instagram"></i></a></li>
                    <li className="list-inline-item"><a href="mailto:contato@ccapucarana.com.br" target="_blank" rel="noreferrer"><i className="fa fa-envelope"></i></a></li>
                </ul>
            </div>
            
            <a href="mailto:contato@ccapucarana.com.br">contato@ccapucarana.com.br</a>
            <p>Desenvolvido por RW Sistemas - {ano}</p>

</section>
  }

export default Footer;