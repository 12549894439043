import React from 'react';
import Menu from './menu';
import Contato from './Contato/contato';

function Contatohome(){
    return <div>
      <Contato/>
    </div>;
  }

export default Contatohome;