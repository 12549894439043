import React from 'react';
import './contato.css';

function Contato(){
    return <div className="container">
    <section className="d-flex bg-light" id="feature-cards">
        <div className="container d-flex justify-content-center">
            <div className="row align-items-center justify-content-center">
                <div className="col-12 align-self-center">
                    <div className="row">
                        <div className="col-12 col-lg-7 mx-auto text-center">
                            <h2 className="display-4">Comunidade Cristã de Apucarana</h2>
                            <p className="text-muted lead mb-5">Abaixo você encontra mais links úteis.</p>
                            <a className="navbar-brand align-items-center">
                                <img src="images/cca/IMG_7039.png" className='img-200-200' />
                            </a>                               
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="iconandroid fab fa-android fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">APP Android</p>
                                            <p className="text-secondary">Baixe nosso APP para plataforma Android.</p>
                                            <a href="https://play.google.com/store/apps/details?id=cca.app.cca" rel="noreferrer" className="btn btn-android mt-3 px-4">Clique aqui para baixar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className="col-12 col-lg-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="iconios fab fa-apple fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">APP IOS</p>
                                            <p className="text-secondary">Baixe nosso APP para plataforma IOS.</p>
                                            <a href="https://apps.apple.com/br/app/cca-apucarana/id1540188343" rel="noreferrer" className="btn btn-ios mt-3 px-4">Clique aqui para baixar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                             
                    </div>                    
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fas fa-users fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">Solicitação de Célula</p>
                                            <p className="text-secondary">Solicite uma Célula.</p>
                                            <a href="http://www.ccapucarana.com.br/solicitacelula/" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fas fa-hand-holding-heart fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">Pedido de Oração</p>
                                            <p className="text-secondary">Faça seu pedido de oração.</p>
                                            <a href="http://www.ccapucarana.com.br/pedidooracao/" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fas fa-church fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">Visitante</p>
                                            <p className="text-secondary">Ficha para visitantes.</p>
                                            <a href="http://www.ccapucarana.com.br/visitantes/" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fas fa-solid fa-phone fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">Telefone</p>
                                            <p className="text-secondary">(43) 3047-0012</p>
                                            <a href="tel:4330470012" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fab fa-whatsapp-square fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">WhatsApp</p>
                                            <p className="text-secondary">(43) 99153-0134</p>
                                            <a href="https://web.whatsapp.com/send?phone=43991530134" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fas fa-at fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">E-mail</p>
                                            <p className="text-secondary">contato@ccapucarana.com.br</p>
                                            <a href="mailto:contato@ccapucarana.com.br" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fab fa-facebook fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">Facebook</p>
                                            <a href="fb://profile/comunidadecristadeapucarana" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fab fa-instagram-square fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">Instagram</p>
                                            <a href="https://www.instagram.com/cca_apucarana/" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fab fa-youtube fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">Youtube</p>
                                            <a href="https://www.youtube.com/c/CCATVON" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                      
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fas fa-podcast fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">PodCast</p>
                                            <a href="https://podcasts.apple.com/br/podcast/cca-comunidade-crist%C3%A3-de-apucarana/id1383125114" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui para baixar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className="col-12 col-lg-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col mx-auto text-center">
                                            <i className="icon fab fa-spotify fa-4x"></i>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <p className="text-info">Spotify</p>
                                            <a href="https://open.spotify.com/show/1GyPj5NQUB8FNw9aibizpX" rel="noreferrer" className="btn btn-info mt-3 px-4">Clique aqui para baixar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                             
                    </div>                                      
                </div>
            </div>
        </div>
    </section>
    
    </div>;
  }

export default Contato;