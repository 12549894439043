import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

function Navbar(){
    return <nav className="navbar navbar-expand-md navbar-dark">

    <div className="container" id="menu">
        <a className="navbar-brand">
            <img src="/images/cca/logo cca.png" alt ="" heigth="28" />
        </a>    
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
            <li className="nav-item">
                <Link to="/app/home" className="nav-link active" aria-current="page">Home</Link>
            </li>                   
            <li className="nav-item">
                <Link to="/app/encontro" className="nav-link active" aria-current="page">Encontro com Deus</Link>
            </li>                  
            <li className="nav-item">
                <Link to="/app" className="nav-link active" aria-current="page">Sair</Link>
            </li>          
            </ul>
        </div>
    </div> 
  </nav>;
  }

export default Navbar;