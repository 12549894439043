import React from 'react';

function Features(){
    return <section id="features">
        <div className="container">
            <div className="row">

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-info-circle fa-6x"></i>
                    <h3>Informações</h3>
                    <p className="pcor">Clique aqui para ver nossas informações</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-hand-holding-medical fa-6x"></i>                        
                    <h3>Colabore</h3>
                    <p className="pcor">Clique aqui para colaborar</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-church fa-6x"></i>
                    <h3>Programação</h3>
                    <p className="pcor">Clique aqui para ver a nossa programação</p>
                </div>                                

            </div>
        </div>
    </section>;
  }

export default Features;