import React from "react";
import './login.css';
import {Link} from 'react-router-dom';

function Login(){
    return <div className="d-flex align-items-center text-center form-container">
      <form className="form-signin">
        <img className="mb-4" src="Images/cca/logo cca.PNG" alt="" width="72" height="57" />
        <h1 className="h3 mb-3 fw-normal">Sistema CCA</h1>

        <div className="form-floating">
          <input type="text" className="form-control" id="floatingInput" placeholder="usuário" />
          <label for="floatingInput">Usuário</label>
        </div>
        <div className="form-floating">
          <input type="password" className="form-control" id="floatingPassword" placeholder="Senha" />
          <label for="floatingPassword">Senha</label>
        </div>

        <button className="w-100 btn btn-lg btn-primary" type="submit">Acessar</button>

        <div className="login-links mt-5">
          <Link to="/app/resetsenha" className="mx-3">Esqueci minha senha</Link>
          <Link to="/app/solicita" className="mx-3">Solicitar acesso</Link>
        </div>

        <p className="mt-5 mb-3 text-muted">&copy; Desenvolvido por RW Sistemas</p>
    </form>
  </div>;
  }

export default Login;