import React from 'react';
import { Link } from 'react-router-dom';

function Menu(){
    return <nav className="navbar navbar-expand-md navbar-dark">

    <div className="container" id="menu">
        <a className="navbar-brand" href="#">
            <img src="images/cca/logo cca.png" alt ="" heigth="28" />
        </a>    
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
            <li className="nav-item">
                <Link to="/" className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#">Informações</a>
            </li>
            <li className="nav-item">
                <Link to="/colabore" className="nav-link">Colabore</Link>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#">Programação</a>
            </li>            
            <li className="nav-item">
                <Link to="/contato" className="nav-link">Contato</Link>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="http://ccapucarana.ddns.net:8075/">Login</a>
            </li>            
            </ul>
        </div>
    </div> 
  </nav>;
  }

export default Menu;